import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import menu from '../menu.yml';
import Header from '../components/header';
import './index.css';

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <div className="homepage--container">
      <article className="homepage">
        <Header>
          <h1>Синопсис книг Библии</h1>
        </Header>

        <div className="table-of-contents">
          {menu.map(({ title, items }, index) => {
            return (
              <div className="toc--column" key={index}>
                <h3>{title}</h3>
                <ul className="toc--sections">
                  {items.map(({ title: sectionTitle, link }, subIndex) => {
                    return (
                      <li key={`${index}-${subIndex}`}>
                        {link ? (
                          <Link to={link}>{sectionTitle}</Link>
                        ) : (
                          sectionTitle
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </article>
    </div>
  </Layout>
);

export default IndexPage;
